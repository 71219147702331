const initialState =  {
    origin: {},
    target: {},
};
export default (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ORIGIN':
            console.log('payload', action.payload);
            return {...state, origin: action.payload};
        case 'GET_TARGET':
            console.log('payload', action.payload);
            return {...state, target: action.payload};
        default:
            return state;
    }
};